.qrcodeBody{
    display: flex;
    justify-content: space-between;
    margin: 15vh 25px 0px 25px;
}

.box2{
    width: 30vw;
    padding: 30px 0px;
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: #fff;
    border-radius: 15px;
}
.box2:hover{
  box-shadow: 2px 2px 4px rgba(0,0,0,.3);
  cursor: pointer;
}
.box2:hover .imgSty{
  transform: scale(1.02);
  transition: all .3s;
}
.title{
  font-weight: 600;
  color: #1d1d1f;
  font-size: 17px;
  font-family: SF Pro SC,SF Pro Display,SF Pro Icons,PingFang SC,Helvetica Neue,Helvetica,Arial,sans-serif;
}
.desc{
    color: #6e6e73;
}
.desc2{
  color: #6e6e73;
  margin-top: 10px;
}
.circleSty {
  height: 20vw;
  padding: 10px 0px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    
  }

  @keyframes qrcode-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

.imgSty{
    width: 30vw;
    height: 30vw;
    width: 100%;
    height: 100%;
}
@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 736px) 
  and (-webkit-min-device-pixel-ratio: 3) { 
    .qrcodeBody{
      display: block;
      margin: 30px 25px;
    }
    .box2{
      width: calc(100% - 20px);
      margin-bottom: 20px;
      padding: 20px 10px;
    }
    .desc,.desc2{
      font-size: 13px;
    }
    .circleSty{
      height: auto;
    }
}