.topBox{
    text-align: left;
    height: 55px;
    background-color: #FFA000;
}
.navBox{
    max-width: 980px;
    padding: 0 20px;
    margin: 0 auto;
}
.logo{
    height: 35px;
    margin-top: 12px;
}